.header-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 30rem;
  width: 100%;
  background-image: url("../../assets/imgs/DESKTOP-BG.png");
  background-position: center;
  background-size: contain;
  background-repeat: repeat;
}

.header-box {
  width: 60%;
  height: 100%;
  display: flex;
}

.header-details {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 30px;
  align-items: center;
  font-size: 80%;
  height: 100%;
  padding-bottom: 10px;
}

.header-details img {
  width: 22rem;
  margin-bottom: 20px;
}

.header-details button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 10rem;
  border-radius: 20px;
  background-color: #330000;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.header-details button:hover {
  transform: scale(1.1);
}

.header-details button span {
  color: #fff;
  font-family: FireSans;
  font-weight: 700;
  font-size: 1.5em;
  padding: 5px;
}

.header-details button p {
  width: 100%;
  color: #330000;
  background-color: #ffa50d;
  font-family: FireSans;
  font-weight: 700;
  font-size: 1em;
  border-radius: 0 0 15px 15px;
  padding: 5px;
}

.header-catarina {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
}

.catarina-desktop {
  width: 30rem;
}

.header-icons {
  display: flex;
  justify-content: flex-end;
  height: 10%;
  padding-top: 20px;
  width: 480px;
}

.header-icons img {
  width: 2rem;
  margin-left: 0.5rem;
}

.header-corda {
  width: 100%;
  height: 1em;
  margin-top: -12px;
  background-image: url("../../assets/imgs/CORDA-HORIZONTAL.png");
  background-size: contain;
  background-repeat: repeat;
}

/* RESPONSIVIDADE */
@media (max-width: 2000px) {
  .header-box {
    width: 900px;
  }
}

@media (max-width: 900px) {
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 45rem;
    width: 100%;
    background-image: url("../../assets/imgs/MOBILE-BG.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .header-box {
    justify-content: center;
  }

  .header-details {
    width: 80%;
  }

  .header-catarina {
    display: none;
  }

  .header-icons {
    display: none;
  }

  .header-corda {
    height: 1.1em;
    margin-bottom: -2px;
  }
}

@media (max-width: 650px) {
  .header-box {
    width: 100%;
    height: 100%;
  }

  .header-details {
    justify-content: space-evenly;
    width: 90%;
  }

  .header-details img {
    width: 350px;
  }
}

@media (max-width: 350px) {
  .header-details img {
    width: 250px;
  }
  
}

@media (min-width: 901px) {
  .catarina-mobile {
    display: none;
  }
}
