.container {
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 7rem;
}
.partners-slider {
  width: 50%;
  margin: 0 auto;
  position: relative;
}

.partners-slider .item {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.partners-slider .item img {
  width: 150px;
}

.react-multiple-carousel__arrow--left::before{
  background-image: url("../../assets/icons/gray-arrow.svg");
  background-color: transparent;
  transform: rotate(180deg);
  content: "" !important;
  left: -20px;
}
.react-multiple-carousel__arrow:hover {
  background: transparent;
}

.react-multiple-carousel__arrow {
  background-color: transparent;
}

.react-multiple-carousel__arrow--right::before {
  background-image: url("../../assets/icons/gray-arrow.svg");
  content: "" !important;
  right: -50px;
}

.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px; 
  height: 20px; 
}

.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  background-color: transparent;
}

@media (max-width: 1200px) {
  .partners-slider .item img {
    width: 150px;
  }
}

@media (max-width: 900px) {
  .react-multiple-carousel__arrow--right::before {
    right: -30px;
  }
  .react-multiple-carousel__arrow--left::before{
    left: -10px;
  }
}

@media (max-width: 700px) {
  .partners-slider .item img {
    width: 160px;
  }
  .partners-slider .item {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
}