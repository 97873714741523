.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 100%;
  background-color: #ffa50d;
}

.footer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 100%;
}

.footer-text {
  display: flex;
  justify-content: flex-start;
  width: 70%;
  color: #330000;
  font-size: 18px;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  width: 480px;
}

.social-icons img {
  width: 30px;
  cursor: pointer;
  margin-left: 0.5rem;
}

@media (max-width: 900px) {
  .footer-text {
    justify-content: center;
  }
  .social-icons {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .social-icons {
    width: 15%;
  }
  .social-icons img {
    width: 25px;
  }
}

@media (max-width: 500px) {
  .social-icons img {
    width: 20px;
  }
}
